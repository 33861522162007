import React from 'react'
import Helmet from 'react-helmet';
import favicon32x32 from '../assets/img/favicon-32x32.png';
import favicon16x16 from '../assets/img/favicon-16x16.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './general.css';

const Layout = ({ children }) => (
  <div>
    <Helmet>
      <title>{'Havaneser vom Heeker Donnerberg'}</title>
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
    </Helmet>
    {children}
  </div>
)

export default Layout
